/* React Tinongo <SearchFilters> component */

import React from 'react';
import classNames from 'classnames';
import {
  ClearRefinements,
  Menu,
  MenuSelect,
  NumericMenu,
  Panel,
} from 'react-instantsearch-dom';
import { orderBy } from 'lodash';

import styles from './search-filters.module.scss';

/* The <SearchFilters> component */
function SearchFilters(props) {
  const classes = classNames({
    [`${styles.searchFilters}`]: true,
    [`${styles.searchFiltersDrawer}`]: props.drawer,
    [`${styles.searchFiltersSidebar}`]: props.sidebar,
  });

  const {
    einzelMannschaftssport,
    indoorOutdoor,
    // eintrittsalter
  } = props.refinements || {};

  return (
    <div className={classes}>
      <div className={styles.searchFilterPanel}>
        <Panel header="Einzel- / Mannsschaftssport">
          <Menu
            attribute="einzelMannschaftssport"
            defaultRefinement={einzelMannschaftssport}
          />
        </Panel>
      </div>
      <div className={styles.searchFilterPanel}>
        <Panel header="Indoor / Outdoor">
          <Menu attribute="indoorOutdoor" defaultRefinement={indoorOutdoor} />
        </Panel>
      </div>
      <div className={styles.searchFilterPanel}>
        <Panel header="Geeignet ab">
          <NumericMenu
            attribute="eintrittsalter"
            items={[
              { label: '<= 4 Jahren', start: 0, end: 4 },
              { label: '5 Jahren', start: 0, end: 5 },
              { label: '6 Jahren', start: 0, end: 6 },
              { label: '7 Jahren', start: 0, end: 7 },
              { label: '8 Jahren', start: 0, end: 8 },
              { label: '9 Jahren', start: 0, end: 9 },
              { label: '10 Jahren', start: 0, end: 10 },
              { label: '11 Jahren', start: 0, end: 11 },
              { label: '12 Jahren', start: 0, end: 12 },
              { label: '13 Jahren', start: 0, end: 13 },
              { label: '>= 14 Jahren', start: 0 },
            ]}
            translations={{
              all: 'Alter egal',
            }}
          />
        </Panel>
      </div>
      <div className={styles.searchFilterPanel}>
        <Panel header="Typ der Sportart">
          <MenuSelect
            attribute="typDerSportart"
            translations={{ seeAllOption: 'Alle anzeigen' }}
            limit={50}
            transformItems={(items) =>
              orderBy(items, ['label', 'count'], ['asc', 'desc'])
            }
          />
        </Panel>
      </div>
      <ClearRefinements translations={{ reset: 'Filter zurücksetzen' }} />
    </div>
  );
}

export default SearchFilters;
