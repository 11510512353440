//
// Gatsby Tinongo Suche > Sportart page
// Route: /suche/sportarten
//

import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  SearchBox,
  Hits,
  Highlight,
  PoweredBy,
  Pagination,
} from 'react-instantsearch-dom';
import { Link, graphql } from 'gatsby';
import { UIContext } from '../../context/ui';

import ActionButton from '../../components/action-button';
import AppShell from '../../components/app-shell';
import Content from '../../components/content';
import Drawer from '../../components/drawer';
import FilterListIcon from '@material-ui/icons/FilterList';
import Scrollbox from '../../components/scrollbox';
import SearchFilters from '../../components/search-filters';
import SEO from '../../components/seo';

import styles from './sportarten.module.scss';

export default function Sports(props) {
  const searchClient = algoliasearch(
    props.data.site.siteMetadata.algoliaAppId,
    props.data.site.siteMetadata.algoliaApiKey
  );

  const Sportart = ({ hit }) => {
    const suitableFrom = (age) => {
      if (age < 4) {
        return '< 4';
      } else if (age > 15) {
        return '> 14';
      } else {
        return age;
      }
    };

    return (
      <Link className={styles.hit} to={`/sportart/${hit.slug}`}>
        <div
          className={styles.hitThumb}
          style={{
            backgroundImage: `url(${hit.imageUrl}?w=150&h=150&fit=fill)`,
          }}></div>
        <div>
          <span>
            <Highlight attribute="titel" hit={hit} />
          </span>
          <br />
          <span className={styles.hitAge}>
            Geeignet ab: {suitableFrom(hit.eintrittsalter)} Jahren
          </span>
        </div>
      </Link>
    );
  };

  // order(items) {
  //   const compare = (a,b) => {
  //     if (parseInt(a.label, 10) > parseInt(b.label, 10)) {
  //       return 1;
  //     } else if (parseInt(b.label, 10) > parseInt(a.label, 10)) {
  //       return -1;
  //     } else {
  //       return 0;
  //     }
  //   };

  //   const label = (item) => {
  //     const rename = {
  //       3: '< 4 Jahre',
  //       4: '4 Jahren',
  //       5: '5 Jahren',
  //       6: '6 Jahren',
  //       7: '7 Jahren',
  //       8: '8 Jahren',
  //       9: '9 Jahren',
  //       10: '10 Jahren',
  //       11: '11 Jahren',
  //       12: '12 Jahren',
  //       13: '13 Jahren',
  //       14: '14 Jahren',
  //       15: '> 14 Jahre'
  //     }

  //     item.label = rename[item.label];
  //     console.log(item.label);
  //   }

  //   items.sort(compare);
  //   map(items, label);
  //   return items;
  // }

  /**
   * Returns the <Search> React element.
   */
  return (
    <>
      <SEO
        title="Suche Sportart"
        description="Hier findest Du die passende Sportart für Dein Kind."
      />
      <AppShell>
        <UIContext.Consumer>
          {(ui) => (
            <InstantSearch
              indexName={props.data.site.siteMetadata.algoliaIndexSports}
              searchClient={searchClient}>
              <Configure hitsPerPage={15} />
              <Content>
                <SearchBox translations={{ placeholder: 'Sportart' }} />

                <div className={styles.main}>
                  <div className={styles.hits}>
                    <Hits hitComponent={Sportart} />
                    <Pagination />
                  </div>

                  {ui.layout === 'desktop' && (
                    <aside className={styles.sidebar}>
                      <SearchFilters
                        sidebar
                        refinements={props.location.state}
                      />
                    </aside>
                  )}
                </div>
                <PoweredBy />
              </Content>

              {ui.layout === 'mobile' && (
                <div>
                  <ActionButton label="Filter" onClick={ui.openActionDrawer}>
                    <FilterListIcon />
                  </ActionButton>
                  <Drawer
                    fullScreen
                    enterBottom
                    isOpen={ui.actionDrawerIsOpen}
                    closeDrawer={ui.closeActionDrawer}>
                    <Scrollbox>
                      <SearchFilters
                        drawer
                        refinements={props.location.state}
                      />
                    </Scrollbox>
                  </Drawer>
                </div>
              )}
            </InstantSearch>
          )}
        </UIContext.Consumer>
      </AppShell>
    </>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        algoliaAppId
        algoliaApiKey
        algoliaIndexSports
      }
    }
  }
`;
